body {
  margin: 0;
  font-size: 14px;
  font-family: Graphik LCG Web, Graphik Arabic Web Regular, -apple-system,
    BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, Lucida Grande,
    Sans-Serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  --primary-background-color: #fafafa;
  --primary-text-color: #222;
  --border-color: #e1e4e8;
  --button-background-color: #fff;
  --button-disabled-color: #757575;
  --button-disabled-border-color: #bdbdbd;
  --button-border-color: #e6ecf0;
  --button-focus-color: #474646;
  --scrollbar-background-color: rgba(113, 122, 116, 0.4);
  --card-background-color: #f5f7f9;
  --color-text-secondary: #586069;
  --blue-background: #d2e3fc;
  --blue-color: #07a;
  --twit-color: #1da1f2;
  --twit-background: rgba(29, 160, 242, 0.1);
  --purple-background: #ffaabf;
  --dark-color: #fff;
  --dark-background: #000;
  --green-color: #17bf63;
  --green-background: rgba(23, 191, 99, 0.1);
  line-height: 1.5 !important;
  scroll-behavior: smooth;
}

html,
body,
main {
  height: 100%;
}

a {
  color: var(--blue-color);
  text-decoration: none;
  font-weight: 600;
}

.sub-title {
  color: var(--primary-text-color);
  font-size: 15px;
  font-weight: 600;
}

.flex-box {
  display: flex;
  align-items: center;
}

.tag-label {
  display: inline-flex;
  background-color: var(--blue-background);
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  color: var(--blue-color);
  border: none;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-size: 11px;
  font-weight: 600;
  padding: 5px 15px;
}

.flex-1 {
  display: flex;
  align-items: center;
  gap: 8px;
}

.advert img {
  margin-left: -8px;
  width: 200px;
}
