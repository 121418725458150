.app-share-icon {
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

.app-share-icon:hover {
  color: var(--green-color);
}
