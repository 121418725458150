.main-404,
.section-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.main-404 {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.section-404 span {
  font-size: 24px;
  font-weight: var(--font-weight-bold);
  display: block;
  border-bottom: 1px solid #eaeaea;
  text-align: center;
  padding-bottom: 20px;
  width: 100px;
}

.section-404 p {
  font-size: 14px;
  font-weight: 400;
}

.section-404 span + p {
  margin: 20px 0 0 0;
}

.back-404 {
  cursor: pointer;
  font-size: 14px;
  margin-left: 5px;
  line-height: 40px;
}

.section-404-ads {
  background: #fff;
  border-radius: 5px;
  padding: 5px 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

@media (min-width: 768px) {
  .section-404 {
    height: 40px;
    flex-direction: row;
  }
  .section-404 span,
  .section-404 p {
    height: 100%;
    line-height: 40px;
  }
  .section-404 span {
    border-bottom: 0;
    border-right: 1px solid #eaeaea;
    padding: 0 20px 0 0;
    width: auto;
  }
  .section-404 span + p {
    margin: 0;
    padding-left: 20px;
  }
}
