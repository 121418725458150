html {
  --nav-color: rgb(103, 123, 140);
  --text-color-1: #4e5b6c;
  --text-color: #232323;
  --navlink-hover: #d74b3f;
  --background-red: #fbd6b7;
  --background: #fafafa;
  --green-color: rgb(23, 191, 99);
  --scrollbar-background-color: #ddd;
}

main {
  height: 100%;
  margin: 0 auto;
}

a:hover {
  color: var(--green-color);
}

header {
  background-color: var(--background);
  color: var(--nav-color);
  letter-spacing: 1px;
  margin: 0 auto;
  position: sticky;
  border-bottom: 1px solid var(--border-color);
  top: 0;
  z-index: 2;
}

.info {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  color: #fff;
  padding: 7.5px 20px;
  font-size: 11px;
}

.audio-intro {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background-color: var(--white);
  padding: 8px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  font-size: 12px;

  figcaption {
    font-size: 12px;
    border-radius: 8px;
    padding: 5px 8px;
  }

  audio {
    height: 40px;
  }
}

.badge {
  width: 150px;
}

nav {
  padding: 12px 30px;
  margin: 0 auto;
  align-items: center;
  display: flex;
}

h1 {
  margin: 0 auto;
  font-size: 50px;
  font-weight: 700;
}

h2 {
  color: #31636e;
}

.orange {
  color: darkorange;
}

.active {
  color: var(--green-color);
}

.nav-title {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  width: 180px;
}

.nav-right {
  float: right;
  width: 100%;
  text-align: right;
}

.nav-right-inner {
  float: right;
  display: flex;
  align-items: center;
}

.nav-item {
  display: flex;
  font-size: 17px;
  align-items: center;
  font-weight: 600;
  margin-left: 20px;
  transition: opacity 0.25s ease-out;
}

.top-section {
  padding-bottom: 0;
  text-align: center;
  height: 100%;
}

.logo {
  margin: 0 auto;
  width: 50px;
}

.main-page {
  height: 100%;
}

.docs {
  margin: 0 auto;
}

.test-image {
  max-width: 500px;
  width: 100%;
}

.nav-link:hover {
  color: var(--green-color);
  font-weight: 600;
}

.home-page {
  height: 100%;
}

.home-page-blurb {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.home-page-blurb-img {
  width: 150px;
  margin: 15px 0;
}

.home-page-blurb-title {
  background: #f5f2f0;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  color: #202124;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  padding: 15px 20px;
  font-size: 18px;
}

.home-page-blurb-desc {
  text-align: center;
  color: var(--nav-color);
  font-size: 30px;
  margin: 15px 0;
  font-weight: 500;
}

.nav-active {
  color: var(--green-color);
}

.docs-flex {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 100%;
}

.docs-flex-left {
  max-width: 300px;
  width: 100%;
}

.docs-flex-right {
  box-sizing: border-box;
  width: 100%;
}

.footer {
  font-weight: 600;
  margin: 0 auto;
  padding: 15px 20px;
  text-align: center;
}

.footer a {
  border-bottom: 2px solid;
  padding-bottom: 3px;
}

.hide-mobile {
  display: none;
}

.nav-icon {
  display: none;
}

.title-blurb {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}

.blurb-code {
  border-radius: 0;
}

.fade-in {
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 0.5s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 738px) {
  .nav-icon {
    display: flex;
  }
  .flex {
    display: none;
  }
  .docs {
    display: block;
  }

  .post {
    margin: 0 auto;
    padding: 20px !important;
  }
}

@media (min-width: 800px) {
  .home-page-blurb-title {
    font-size: 40px;
  }

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: var(--scrollbar-background-color);
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background: none;
  }
}
