.sidebar {
  height: 100vh;
  overflow: auto;
}

.sidebar-inner {
  height: 100%;
  position: fixed;
  width: 100%;
  max-width: 300px;
  background-color: #fff;
  box-sizing: border-box;
  border: 0;
  border-right: 1px solid var(--border-color);
  z-index: 999;
}

.sidebar-title {
  border: 0;
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 25px;
  font-size: 15px;
  font-weight: 700;
  box-sizing: border-box;
}

.sidebar-title-left {
  width: 100%;
}

.sidebar-title-right {
  float: right;
  display: flex;
  align-items: center;
}

.sidebar-nav-item-container {
  overflow-y: scroll;
  height: calc(100vh - 185px);
}

.sidebar-nav-item {
  border: 0;
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 25px;
  font-size: 15px;
  font-weight: 450;
  box-sizing: border-box;
}

.sidebar-nav-item-text {
  margin-left: 8px;
}

.sidebar-info {
  padding: 20px;
  font-size: 13px;
  user-select: text;
}

.sidebar-info-title {
  font-size: 15px;
  font-weight: bold;
  margin: 10px 0;
}

.sidebar-info-title:first-child {
  margin-top: 0;
}

.sidebar-info-text {
  font-size: 13px;
}

.sidebar-info-text p {
  margin: 10px 0;
}

.sidebar-info ul {
  margin: 0;
  padding: 0;
  padding-left: 10px;
}

@media only screen and (max-width: 736px) {
  .sidebar {
    display: none;
  }
}
